/* Cookies */

const setCookie = (name,value,days=365) => {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
const eraseCookie = (name) => {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

const getHeaderHeight = ($width) => {
    if($width=='mobile') {
        return 64;
    }
    else{
        return 64;
    }
}


/* Media Screen */
const mobile = () => {
    return window.matchMedia('(max-width:768px)').matches;
}
const mobile_r = () => {
    return window.matchMedia('(min-width:769px)').matches;
}

const tablet = () => {
    return window.matchMedia('(max-width:1024px)').matches;
}
const tablet_r = () => {
    return window.matchMedia('(min-width:1025px)').matches;
}

/* SLIDE UP */
const slideUp = (target, duration=500) => {

    if(!target) return;
    
    target.style.transitionProperty = 'height, margin, padding';
    target.style.transitionDuration = duration + 'ms';
    target.style.boxSizing = 'border-box';
    target.style.height = target.offsetHeight + 'px';
    target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    window.setTimeout( () => {
          target.style.display = 'none';
          target.style.removeProperty('height');
          target.style.removeProperty('padding-top');
          target.style.removeProperty('padding-bottom');
          target.style.removeProperty('margin-top');
          target.style.removeProperty('margin-bottom');
          target.style.removeProperty('overflow');
          target.style.removeProperty('transition-duration');
          target.style.removeProperty('transition-property');
          //alert("!");
    }, duration);
}

/* SLIDE DOWN */
const slideDown = (target, duration=500) => {

    if(!target) return;
    if(target.checkVisibility()) return;

    target.style.removeProperty('display');
    let display = window.getComputedStyle(target).display;
    if (display === 'none') display = 'block';
    target.style.display = display;
    let height = target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    target.offsetHeight;
    target.style.boxSizing = 'border-box';
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + 'ms';
    target.style.height = height + 'px';
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    window.setTimeout( () => {
      target.style.removeProperty('height');
      target.style.removeProperty('overflow');
      target.style.removeProperty('transition-duration');
      target.style.removeProperty('transition-property');
    }, duration);
}

/* TOOGLE */
const slideToggle = (target, duration = 500) => {
    if (window.getComputedStyle(target).display === 'none') {
      return slideDown(target, duration);
    } else {
      return slideUp(target, duration);
    }
}

// Delay funcion
const debounce = ( fn, threshold=100 ) => {
    let timeout;
    threshold = threshold || 100;
    return function debounced() {
        clearTimeout( timeout );
        let args = arguments;
        let _this = this;
        function delayed() {
            fn.apply( _this, args );
        }
        timeout = setTimeout( delayed, threshold );
    };
}

const scrollToSection = (section, offset=30) => {
    if (section) {
        let top = section.getBoundingClientRect().y + window.pageYOffset - getHeaderHeight(mobile() ? 'mobile' : 'desktop') - offset;
        if (document.querySelector('body.admin-bar')) {
            if (mobile()) {
                top -= 46;
            }
            else {
                top -= 32;
            }
        }

        if (top < 0) {
            top = 0;
        }
        window.scrollTo({
            top: top,
            behavior: 'smooth',
        });
    }
}

export { setCookie, getCookie, eraseCookie, getHeaderHeight, mobile, mobile_r, tablet, tablet_r, slideUp, slideDown, slideToggle, debounce, scrollToSection }